


































































































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import KeFu from "../layout/KeFu.vue";

@Component({
  components: { LayoutHeader,LayoutFooter,KeFu },
})
export default class Goods extends Vue {
  @Provide() siteConfig:any = [];
  @Provide() mobile:String = '';
  @Provide() email:String = '';
  @Provide() goods_info:any = [];
  @Provide() dc_fields: any = [];
  @Provide() extend_fields: any = [];
  @Provide() goods_id:Number = 0;
  @Provide() buy_num:Number = 0;
  @Provide() dgLoginVisible:Boolean = true;
  created() {
    let utils = (this as any).$utils;
    let goods_id = this.$route.query.id;
    this.goods_id = Number(goods_id);
    utils.navMobile(2,goods_id);
    // goods_id = this.$route.params.id;
    // console.log('goods_id',goods_id)
    this.getSystemConfig();
    this.initGoods(goods_id);
    this.mobile = utils.praseStrEmpty(localStorage.getItem('member_mobile'));
    this.email = utils.praseStrEmpty(localStorage['email']);
  }
  toIndex() {
    //   this.$router.push('/');
      this.$router.go(-1)
  }
  // 关闭登录页面
  closeLoginDialog() {
      this.dgLoginVisible = false;
  }
  // 提交订单
  createOrder() {
      let utils = (this as any).$utils;
      let refs:any = this.$refs;
      // 检查是否强制注册购买
      if(this.siteConfig.webregtype == 2 && utils.praseStrEmpty(localStorage.tsToken) == '') {
          this.dgLoginVisible = true;
          refs.headerChild.login();
          return;
      }

      if(this.siteConfig.order_search_condition == 1 && 
		 this.siteConfig.goods_buy_mobile_open == 1 &&  
		 !utils.checkMobile(this.mobile)) {
			this.$message({
                showClose: true,
                message: '请输入正确的手机号码',
                type: 'error'
            });
            refs.mobile.focus();
            return false;
	  }

      if(this.siteConfig.order_search_condition == 2 && 
		 !utils.checkEmail(this.email)) {
			this.$message({
                showClose: true,
                message: '请输入正确的邮箱',
                type: 'error'
            });
            refs.email.focus();
            return false;
	  }

      // 扩展字段
      let extend_vals = [];
      for (let index = 0; index < this.extend_fields.length; index++) {
        let item = this.extend_fields[index];
        let val = utils.praseStrEmpty(this.extend_fields[item]);
        if(val == '') {
            this.$message({
              showClose: true,
              message: this.dc_fields[index].tip,
              type: 'error'
            });
            return false;
        }
        let t = {title:this.dc_fields[index].title,val:val};
        extend_vals.push(t);         
      }
      // 购买数量检查
      if(this.buy_num <= 0) {
          this.$message({
            showClose: true,
            message: '购买数量不能为空',
            type: 'error'
          });
          return false;
      }
      let forms = {
        m: "order.createOrder",
        goods_id: this.goods_id,
        goods_number: this.buy_num,
        mobile: this.mobile,
        email: this.email,
        extend_vals: JSON.stringify(extend_vals)
      };
      (this as any).$ajax.httpPost(
        "/api/api/router",
        forms,
        (res: any) => {
            if (res.data.code === 0) {
                let order_sn = res.data.data.order_sn;
                localStorage['email'] = this.email;
                this.$router.push({ name: 'order', query: { sn: order_sn }});
            } else {
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: 'error'
                });

                if(res.data.code === 2) {
                    setTimeout(() => {
                        this.dgLoginVisible = true;
                        refs.headerChild.login();
                    }, 2000);
                }
            }
        },
        (err: any) => {
            console.log(err);
        }
      );
  }
  // 获取配置信息
  getSystemConfig() {
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.siteConfig = res.data.data.config;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 初始化商品信息
  initGoods(goods_id:any) {
    let that = this;
    let utils = (this as any).$utils;
    let p = {
      m: 'goods.getGoodsInfo',
      goods_id: goods_id
    };
    (this as any).$ajax.httpPost(
        "/api/api/router",
        p,
        (res: any) => {
        if (res.data.code === 0) {
            this.goods_info = res.data.data.goods_info;
            let dc_fields = this.goods_info.dc_field;
            document.title = this.goods_info.goods_name;
            dc_fields.forEach((item:any,index:number) => {
                item.col_name = `column_${index}`;
                this.extend_fields.push(item.col_name);
                this.dc_fields.push(item);
            })

            if(this.goods_info.goods_stock <= 0) {
                this.$confirm('店主正在努力补货中，请您看看本店的其它宝贝哦', '温馨提示', {
                    confirmButtonText: '确定',
                    type: 'info',
                    showCancelButton: false,
                    showClose: false,
                    center: true
                    }).then(() => {
                        this.toIndex();
                    }).catch(() => {})
                return;
            }

            let goods_msgtip = utils.praseStrEmpty(this.goods_info.goods_msgtip)
            if(goods_msgtip != "") {
                this.$confirm(this.goods_info.goods_msgtip, '温馨提示', {
                    confirmButtonText: '确定',
                    type: 'info',
                    showCancelButton: false,
                    showClose: false,
                    center: true
                    }).then(() => {

                    }).catch(() => {})
            }
        } else {
            this.$message({
            message: res.data.msg,
            type: "error"
            });
            setTimeout(() => {
                that.toIndex();
            }, 2000);
        }
        },
        (err: any) => {
            console.log(err);
        }
    );
  }
}
